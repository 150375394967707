import React from 'react'
import Svg from '../Svg/Svg'
import { SvgProps } from '../Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 805.13 392.17" {...props}>
      <g id="Laag_2" data-name="Laag 2">
        <g id="Layer_1" data-name="Layer 1">
          <path
            fill="#595c7f"
            d="M622.16,356.41s-55.39,26.42-105.1,35.74L267.28,321.33s-88.65-51.06-108.4-139.59C139.61,95.43,153,37.33,153,37.33s93.66,44.2,280.58,65.82c132.95,15.38,371.52-.4,371.52-.4s-22.74,73.66-80.53,154.78C668.73,336,622.16,356.41,622.16,356.41Z"
          />
          <path
            fill="#838aa3"
            d="M661.16,150.34C651,152.61,524.31,176.83,353,161.1S-4.63,66.36.09,62.42C52.05,19,165.61,1.48,165.61,1.48S295.4-7.27,505,17.82c195.17,23.37,300.13,84.93,300.13,84.93S715.1,138.31,661.16,150.34Z"
          />
          <path
            fill="#bbc1d2"
            opacity="0.4"
            d="M744,75.62c0-.21.05-.42.07-.64C693.89,55.53,613.35,30.79,505,17.82,295.4-7.27,165.61,1.48,165.61,1.48S52.05,19,.09,62.42C-2.69,64.74,60.78,93.08,148.4,119.2q21.53,2.3,43.56,4.33C488.83,150.8,736,129.35,744,75.62Z"
          />
          <path
            fill="#595c7f"
            d="M517.06,392.15l-196-18c-36.87-3.39-148.44-19.57-148.44-19.57S99.17,303.05,54.71,220.22C3.89,125.56.09,62.42.09,62.42s177.26,58.23,364.17,79.86c133,15.37,303.6,6.49,303.6,6.49S660.57,209.13,595,302C547.05,369.84,517.06,392.15,517.06,392.15Z"
          />
          <path
            fill="#58486c"
            d="M190.81,11.36S93.51,24,47.65,57.23c70.6,27,200.9,52.71,351.9,66.58,84.14,7.73,163.11,10.72,229.81,9.53,47.36-9.27,125.19-36.48,125.19-36.48S661.45,47.15,489.5,27C304.85,5.45,190.81,11.36,190.81,11.36Z"
          />
          <path
            fill="#443757"
            d="M185.41,47.46s114-5.92,298.7,15.68C585.9,75,660.06,97.32,703.63,113.52c28.28-8.75,50.92-16.66,50.92-16.66S661.45,47.15,489.5,27C304.85,5.45,190.81,11.36,190.81,11.36S93.51,24,47.65,57.23C59.49,61.76,73,66.26,88,70.67,133.79,54.18,185.41,47.46,185.41,47.46Z"
          />
          <path
            fill="#868ca7"
            opacity="0.6"
            d="M364.26,142.29C258,130,154.81,105.87,85.59,87.34a208.66,208.66,0,0,0-11,42.27c-13.05,87.33,31.89,170.09,112.06,227,31.14,4.44,105.6,14.91,134.41,17.55l196,18S547,369.86,595,302c65.6-92.87,72.89-153.23,72.89-153.23S497.21,157.67,364.26,142.29Z"
          />
          <path
            fill="#9da4ba"
            d="M667.85,148.84s-170.65,8.89-303.6-6.49c-70.49-8.15-139.6-21.52-198.36-35a147.55,147.55,0,0,0-3.29,16c-16.27,108.83,92.27,208.24,242.42,222,65.73,6,128.53-5.33,179.74-29.14q5-6.72,10.2-14.13C660.56,209.2,667.85,148.84,667.85,148.84Z"
          />
          <path
            fill="#1a1a1a"
            d="M668.09,124c5.7-1.58,14.81-4.32,20.46-6-35-8.34-84-17.63-157.32-29.3C453.42,76.3,381.36,58,353.61,55.77c-10-.8-15.25,2.19-11.48,6C345.45,65,426.1,79,532,99.7,594.75,112,630.51,119,668.09,124Z"
          />
          <path
            fill="#1a1a1a"
            d="M431.05,93.19C351.41,79.07,278.21,59.26,252.41,57.38c-8.36-.61-13,2-9.63,5.56,3.22,3.37,78.6,19.85,188.75,41.29,70.11,13.65,123.21,24.18,159,29.49,8.46.05,24.58-.2,32.71-.28C604.44,126.33,523.46,109.57,431.05,93.19Z"
          />
          <path
            fill="#1a1a1a"
            d="M346.93,104C267.09,86.78,194.18,63.36,169.55,61c-7.51-.71-11.92,2.42-8.9,5.91s81.89,25.61,186.1,48.13c20.58,4.44,13.69,2.58,32.7,6.8,1.14.1,45,4.32,65.49,5.68C410,118.63,393.18,114,346.93,104Z"
          />
          <path
            fill="#bebebe"
            d="M277.77,129.18c-44.1-6.91-90.3-17.19-134.69-27.34,47.43,15.45,99.28,29.23,150.34,37.24,123.52,19.37,214.72,16,265.46,12.25C493,152.28,401.66,148.62,277.77,129.18Z"
          />
          <path
            fill="#b74145"
            d="M593.71,62.3c-.44-1.17-5-.5-10.24,1.48s-9.07,4.55-8.63,5.72c.13.32.56.5,1.23.56a18.84,18.84,0,0,0,7.33.72c4.51-.26,8.11-1.39,8.05-2.53,0-.69-1.38-1.22-3.41-1.48C591.68,65.06,594.07,63.24,593.71,62.3Z"
          />
          <path
            fill="#e08778"
            d="M600,84.82c-5.86-4.33-10.44,2.8-17.44,6.24-5,2.46-11.33,1.14-12.73-5.09-2-8.83,13.43-17.53,7.64-18-13.88-1-22.79,9.54-22.79,20.36s7.9,22.93,22.57,22.54C601,110.28,608.7,91.26,600,84.82Z"
          />
          <path
            fill="#d5806f"
            d="M600,84.82a8.46,8.46,0,0,0-1-.63c.06,8-8.72,18.49-26.22,19A20.91,20.91,0,0,1,556,96.06c2.79,8.15,10,15.16,21.27,14.86C601,110.28,608.7,91.26,600,84.82Z"
          />
          <path
            fill="#b74145"
            d="M590,95.86a25.49,25.49,0,0,1-1.7-8.47c-1.26.91-2.69,1.89-4.09,2.78a26.24,26.24,0,0,0,1.75,7.25,24.05,24.05,0,0,0,6.72,9.74A26.48,26.48,0,0,0,596,105,24.84,24.84,0,0,1,590,95.86Z"
          />
          <path
            fill="#b74145"
            d="M576.42,99a26.34,26.34,0,0,1,.23-6.79,9.52,9.52,0,0,1-3.95-1.51,25.23,25.23,0,0,0-.58,8.75,24.2,24.2,0,0,0,4.12,11.51c.32,0,.44,0,1,0a31.94,31.94,0,0,0,3.61-.3A24.19,24.19,0,0,1,576.42,99Z"
          />
          <path
            fill="#b74145"
            d="M558.41,95.32a26.14,26.14,0,0,0-1,4.06,20.38,20.38,0,0,0,3.83,5.33,26.59,26.59,0,0,1,1.28-8,24.1,24.1,0,0,1,7.28-10.82c0-.06-.06-.32-.07-.38a9.67,9.67,0,0,1,.39-4C565.23,83.76,560.55,88.87,558.41,95.32Z"
          />
          <path
            fill="#b74145"
            d="M561,81.48A24.33,24.33,0,0,1,571.25,79a31.46,31.46,0,0,1,2.65-3.8,23.42,23.42,0,0,0-14.64,2.13c-.7.35-1.31.65-2,1.05a22.73,22.73,0,0,0-2.5,7.37A25.33,25.33,0,0,1,561,81.48Z"
          />
          <path
            fill="#dfc2c2"
            d="M583,95.94c1,1.68,4.44,1.49,7.66-.45s5-4.86,4-6.55-4.44-1.49-7.66.44S582,94.25,583,95.94Z"
          />
          <path
            fill="#b74145"
            d="M224.93,32.63c-.53-1.14-5.06-.17-10.12,2.16s-8.74,5.16-8.22,6.29c.14.31.59.47,1.26.48a18.88,18.88,0,0,0,7.37.22c4.48-.56,8-1.93,7.85-3.07-.09-.68-1.45-1.12-3.49-1.25C223.08,35.51,225.35,33.53,224.93,32.63Z"
          />
          <path
            fill="#e08778"
            d="M232.72,54.66c-6.14-3.92-10.23,3.51-17,7.41-4.83,2.79-11.23,1.91-13.05-4.22-2.57-8.67,12.21-18.39,6.41-18.42-13.92-.08-22.09,11.06-21.36,21.86s9.44,22.34,24,20.95C235.46,80,241.84,60.49,232.72,54.66Z"
          />
          <path
            fill="#d5806f"
            d="M232.72,54.66a8,8,0,0,0-1-.55c.62,8-7.43,19-24.87,20.69a20.93,20.93,0,0,1-17.26-5.94c3.34,8,11.06,14.45,22.23,13.38C235.46,80,241.84,60.49,232.72,54.66Z"
          />
          <path
            fill="#b74145"
            d="M223.48,66.36A25.61,25.61,0,0,1,221.21,58c-1.19,1-2.55,2.07-3.89,3a25.63,25.63,0,0,0,2.24,7.12,24,24,0,0,0,7.37,9.26,26.12,26.12,0,0,0,3.22-2.39A24.69,24.69,0,0,1,223.48,66.36Z"
          />
          <path
            fill="#b74145"
            d="M210.17,70.37a25.58,25.58,0,0,1-.24-6.79,9.51,9.51,0,0,1-4-1.24,25.32,25.32,0,0,0,0,8.77,24.08,24.08,0,0,0,4.9,11.21c.31,0,.43,0,1,0a33.48,33.48,0,0,0,3.58-.54A24,24,0,0,1,210.17,70.37Z"
          />
          <path
            fill="#b74145"
            d="M192,68a26.21,26.21,0,0,0-.73,4.12,20.77,20.77,0,0,0,4.19,5.06,26.61,26.61,0,0,1,.73-8.1,24.12,24.12,0,0,1,6.52-11.29c0-.06-.07-.31-.09-.38a9.52,9.52,0,0,1,.13-4C198,56,193.64,61.38,192,68Z"
          />
          <path
            fill="#b74145"
            d="M193.59,54a24.59,24.59,0,0,1,10.06-3.15,31.07,31.07,0,0,1,2.39-4A23.34,23.34,0,0,0,191.57,50c-.67.39-1.25.73-1.88,1.17a23.16,23.16,0,0,0-2,7.53A25.69,25.69,0,0,1,193.59,54Z"
          />
          <path
            fill="#dfc2c2"
            d="M216.52,66.91c1.13,1.61,4.53,1.18,7.61-1s4.66-5.2,3.53-6.81-4.53-1.19-7.61,1S215.39,65.29,216.52,66.91Z"
          />
          <path
            fill="#b74145"
            d="M657.8,96.38c.87-.9-1.65-4.79-5.63-8.69s-7.92-6.35-8.79-5.45c-.24.24-.23.71,0,1.35a18.75,18.75,0,0,0,2.41,7c2.11,4,4.65,6.79,5.66,6.25.6-.32.53-1.76-.08-3.71C654.44,95.68,657.1,97.09,657.8,96.38Z"
          />
          <path
            fill="#e08778"
            d="M640,111.49c1.49-7.13-6.9-8.32-13-13.25-4.33-3.52-5.78-9.81-.69-13.69,7.19-5.49,21.53,4.88,19.5-.56-4.88-13-18.19-16.71-28-12.19s-17.53,16.75-11,29.92C617.25,123,637.75,122.08,640,111.49Z"
          />
          <path
            fill="#d5806f"
            d="M640,111.49a7.16,7.16,0,0,0,.16-1.16c-7.28,3.42-20.44-.19-28.18-15.9a20.94,20.94,0,0,1-.58-18.25c-6.24,5.95-9.57,15.47-4.61,25.54C617.25,123,637.75,122.08,640,111.49Z"
          />
          <path
            fill="#b74145"
            d="M625.75,107a25.46,25.46,0,0,1,7-5.08c-1.35-.76-2.84-1.65-4.23-2.56a26.53,26.53,0,0,0-5.86,4.62,24,24,0,0,0-6,10.18,25.22,25.22,0,0,0,3.37,2.17A24.62,24.62,0,0,1,625.75,107Z"
          />
          <path
            fill="#b74145"
            d="M617.27,96a26.07,26.07,0,0,1,6.27-2.63,9.36,9.36,0,0,1-.28-4.22,25.15,25.15,0,0,0-8.2,3.13,24.16,24.16,0,0,0-8.74,8.55c.13.29.15.41.41,1a30.85,30.85,0,0,0,1.78,3.16A24.1,24.1,0,0,1,617.27,96Z"
          />
          <path
            fill="#b74145"
            d="M613.06,78.09a27.05,27.05,0,0,0-4.12.79,20.46,20.46,0,0,0-3.24,5.71,27,27,0,0,1,7.83-2.2,24.08,24.08,0,0,1,12.87,2.1l.32-.23A10,10,0,0,1,630.48,83C626.41,79.46,619.8,77.34,613.06,78.09Z"
          />
          <path
            fill="#b74145"
            d="M626.71,74.66A24.46,24.46,0,0,1,633.23,83a31.66,31.66,0,0,1,4.55.82,23.26,23.26,0,0,0-8-12.41A20.08,20.08,0,0,0,628,70a22.9,22.9,0,0,0-7.74.8A25.54,25.54,0,0,1,626.71,74.66Z"
          />
          <path
            fill="#dfc2c2"
            d="M622.76,100.69c-1.11,1.63.5,4.66,3.6,6.77s6.51,2.51,7.62.89-.5-4.66-3.6-6.77S623.87,99.06,622.76,100.69Z"
          />
        </g>
      </g>
    </Svg>
  )
}

export default Icon
